  .container {
    font-size: 21px;
    font-family: "brandon-grotesque","HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
  }

  .hello-image {
    background-color: #fff;
    width: 75%;
    margin: auto;    
    display: block;
    overflow: hidden;
  }

  .bg-color{
    background-color: rgb(160,218,255);
    display: block;
  }
  
  .project-title {
    font-size: 3em;
    font-family: 'Montez', cursive;
  }

  .proj-img {
    padding-bottom: 20px;
  }