  .container {
    font-size: 21px;
    font-family: "brandon-grotesque","HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
  }
  
  header{
    padding: 28px 0px;
  }

  nav {
    padding: 0px 15px;
  }

  .bg-color{
    background-color: rgb(160,218,255);
    display: block;
  }