  .container {
    font-size: 21px;
    font-family: "brandon-grotesque","HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
  }
  
  .title{
    font-size: 28px;
  }

  .bio-image {
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    clip-path: circle(60px at center);
  }

  .hello-image {
    background-color: #fff;
    width: 75%;
    margin: auto;    
    display: block;
    overflow: hidden;
  }

  .bg-color{
    background-color: rgb(160,218,255);
    display: block;
  }

  .about-me {
    padding: 84px 0px 84px;
  }

  .about-me-article {
    margin: 0 auto;
    max-width: 460px;
  }

  .i-am-taher {
    font-size: 6em;
    font-family: 'Montez', cursive;
  }